import { graphql, useStaticQuery } from 'gatsby';
import ImageIn from 'gatsby-image';
import { Box } from 'juhuui';
import React, { useState } from 'react';

import theme from '../theme';

const Img = Box.with({
  as: ImageIn,
});

interface FluidObject {
  aspectRatio: number;
  src: string;
  srcSet: string;
  sizes: string;
  base64?: string;
  tracedSVG?: string;
  srcWebp?: string;
  srcSetWebp?: string;
  media?: string;
}

export interface ImageType {
  childImageSharp?: {
    fluid: FluidObject;
  };
}

interface ImageProps {
  h?: string[];
  height: string[];
  aspectRatio: boolean;
  image: ImageType | undefined;
  alt: string;
  [x: string]: any;
}

const Image = ({
  image,
  h,
  height = [],
  aspectRatio = false,
  absolute = false,
  css,
  srcsetWidthDesktop = '100vw',
  srcsetWidthMobile = '100vw',
  alt = '',
  ...props
}: ImageProps) => {
  // eslint-disable-next-line unicorn/no-null
  if (!image) return null;
  const aRatio = image?.childImageSharp?.fluid.aspectRatio;
  const width = aspectRatio
    ? (h || height).map((h) => {
        return `calc(${theme.sizes[h]} * ${aRatio})`;
      })
    : 'initial';

  return (
    <Img
      alt={alt}
      className="image-wrapper"
      css={{
        column: '1/span 12',
        ...(aspectRatio && { width }),
        h: h || height,
        ...css,
      }}
      fluid={{
        ...image?.childImageSharp?.fluid,
        sizes: `(min-width: 68em) ${srcsetWidthDesktop}, ${srcsetWidthMobile}`,
      }}
      style={{ position: absolute ? 'absolute' : 'relative' }}
      {...props}
    />
  );
};

export const query = graphql`
  fragment Image on File {
    childImageSharp {
      fluid(
        maxWidth: 1440
        quality: 75
        srcSetBreakpoints: [320, 640, 800, 1024, 1440, 1600, 1920, 2560]
      ) {
        ...GatsbyImageSharpFluid_withWebp
        aspectRatio
      }
    }
  }
`;

export default Image;
